<template>
  <section class="section shop" v-if="authUser">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 order-2 order-lg-1">
          <Sidebar></Sidebar>
        </div>

        <div class="col-lg-9 order-1 order-lg-2 mb-6 mb-lg-0">
          <section class="section mb-6">
            <div class="section__header mb-4">
              <h1
                class="section__title h3"
                v-t="'account.dashboard.title'"
              ></h1>
            </div>

            <div class="section__body">
              <div class="section__content hentry text-sm">
                <span class="d-block mb-2">
                  <strong>
                    {{
                      $t("account.dashboard.greeting", {
                        name: authUser.displayName,
                      })
                    }}
                  </strong>
                </span>

                <p v-t="'account.dashboard.content'">
                  From your Account Dashboard you have the ability to view a
                  snapshot of your recent account activity and update your
                  account information. Select a link below to view or edit
                  information.
                </p>
              </div>
            </div>
          </section>

          <template
            v-if="banners.data && !isFetchingBanners"
            :banners="banners.data"
          >
            <section
              class="section banners banners--colored bg-light"
              v-if="
                Object.keys(banners.data.account).length > 0 &&
                  banners.data.account[0].title != null
              "
            >
              <div class="container">
                <div class="row row--col-margin-small">
                  <div
                    class="col-lg-8 offset-lg-2
"
                    v-for="(banner, i) in banners.data.account"
                    :key="i"
                    :banner="banner"
                  >
                    <div class="banner box">
                      <a
                        :href="banner.permalink"
                        class="banner__body box__body d-flex align-items-center"
                      >
                        <div class="banner__media media media--clipped ratio">
                          <div class="media__wrapper">
                            <img
                              :src="banner.thumbnail"
                              class="media__src img-fit"
                              :alt="banner.title"
                              v-if="banner.thumbnail"
                            />
                          </div>
                        </div>

                        <div class="banner__content">
                          <div class="banner__title h5">
                            <strong>
                              {{ banner.title }}
                            </strong>
                          </div>
                        </div>
                        <span class="banner__action ml-auto">
                          <span class="btn btn-sm btn-secondary btn-icon-only">
                            <i class="icon icon-keyboard_arrow_right"></i>
                          </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </template>

          <section
            v-else-if="isFetchingBanners"
            class="section"
          >

            <ContentLoader
              viewBox="0 0 1180 270"
              style="width: 100%;"
            >
              <rect x="0" y="0" width="1180" height="270" />
            </ContentLoader>
          </section>

          <section class="section mb-6" v-if="orders[0]">
            <div class="section__header d-flex align-items-center mb-2">
              <h4
                class="section__title mb-0"
                v-t="'account.dashboard.recent-order.title'"
              >
                Recent order
              </h4>
              <router-link
                :to="{ name: 'Orders' }"
                class="btn btn-sm btn-empty btn-empty-primary ml-auto"
              >
                <span v-t="'account.dashboard.recent-order.view-all'">
                  View all
                </span>
              </router-link>
            </div>

            <hr class="mt-3 mb-5" v-if="orders[0]" />

            <div class="section__body" v-if="orders[0]">
              <!-- v-for="(order, i) in orders" -->
              <Order :key="0" :order="orders[0]"></Order>
            </div>
          </section>

          <!-- <AccountInformation /> -->

          <section class="section mb-6">
            <div class="section__header d-flex align-items-center mb-3">
              <h4
                class="section__title mb-0"
                v-t="'account.dashboard.address-book.title'"
              >
                Address book
              </h4>

              <a
                :href="
                  $t('account.dashboard.address-book.request-a-change.url')
                "
                target="_blank"
                class="btn btn-sm btn-empty btn-empty-primary ml-auto"
              >
                <span
                  v-t="'account.dashboard.address-book.request-a-change.title'"
                  >Request a change</span
                >
              </a>
              <!-- <router-link
                to="/account/address-book"
                class="btn btn-sm btn-empty btn-empty-primary ml-auto"
              >
                <span v-t="'account.dashboard.address-book.manage-address'">
                  Manage address
                </span>
              </router-link> -->
            </div>

            <div class="section__body box bg-light">
              <div class="box__content">
                <div class="box-inner">
                  <div class="row row--col-margin-semismall">
                    <div class="col-lg-6">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="'account.dashboard.address-book.default-billing'"
                        >
                          Default billing address
                        </span>
                        <!-- <router-link
                          class="btn btn-sm btn-empty btn-empty-primary ml-auto"
                          :to="{ name: 'Address book' }"
                        >
                          <span v-t="'account.dashboard.address-book.edit'"
                            >Edit</span
                          >
                        </router-link> -->
                      </div>
                      <div class="box-inner__content text-sm">
                        <div v-if="this.userBillingAddress">
                          <div>
                            <span>{{ this.userBillingAddress.firstname }}</span
                            >&nbsp;<span>{{
                              this.userBillingAddress.lastname
                            }}</span
                            ><br />
                          </div>

                          <div v-if="this.userBillingAddress.street">
                            <template
                              v-for="(streetItem, i) in this.userBillingAddress
                                .street"
                              :key="i"
                            >
                              <span class="d-block">{{ streetItem }}</span>
                            </template>
                          </div>
                          <span v-if="this.userBillingAddress.postcode">
                            {{ this.userBillingAddress.postcode }}
                          </span>
                          <span v-if="this.userBillingAddress.city"
                            >&nbsp;{{ this.userBillingAddress.city }}</span
                          >
                          <span
                            v-if="this.userBillingAddress.region.region"
                            class="d-block"
                          >
                            {{ this.userBillingAddress.region.region }}
                          </span>
                          <span
                            v-if="this.userBillingAddress.country_code"
                            class="d-block"
                          >
                            {{ this.userBillingAddress.country_code }}
                          </span>
                          <span
                            v-if="this.userBillingAddress.telephone"
                            class="d-block"
                          >
                            {{ this.userBillingAddress.telephone }}
                          </span>
                        </div>
                        <div v-else>
                          <p v-t="'account.dashboard.address-book.no-billing'">
                            You have no billing addresses yet.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6" v-if="this.userShippingAddress">
                      <div class="box-inner__header d-flex">
                        <span
                          class="box-inner__title text-subtitle"
                          v-t="
                            'account.dashboard.address-book.default-shipping'
                          "
                        >
                          Default shipping address
                        </span>
                        <!-- <router-link
                          class="btn btn-sm btn-empty btn-empty-primary ml-auto"
                          :to="{ name: 'Address book' }"
                        >
                          <span v-t="'account.dashboard.address-book.edit'"
                            >Edit</span
                          >
                        </router-link> -->
                      </div>
                      <div class="box-inner__content text-sm">
                        <div>
                          <div>
                            <span>{{ this.userShippingAddress.firstname }}</span
                            >&nbsp;<span>{{
                              this.userShippingAddress.lastname
                            }}</span
                            ><br />
                          </div>

                          <div v-if="this.userShippingAddress.street">
                            <template
                              v-for="(streetItem, i) in this.userShippingAddress
                                .street"
                              :key="i"
                            >
                              <span class="d-block">{{ streetItem }}</span>
                            </template>
                          </div>
                          <span v-if="this.userShippingAddress.postcode">
                            {{ this.userShippingAddress.postcode }}
                          </span>
                          <span v-if="this.userShippingAddress.city"
                            >&nbsp;{{ this.userShippingAddress.city }}</span
                          >
                          <span
                            v-if="this.userShippingAddress.region.region"
                            class="d-block"
                          >
                            {{ this.userShippingAddress.region.region }}
                          </span>
                          <span
                            v-if="this.userShippingAddress.country_code"
                            class="d-block"
                          >
                            {{ this.userShippingAddress.country_code }}
                          </span>
                          <span
                            v-if="this.userShippingAddress.telephone"
                            class="d-block"
                          >
                            {{ this.userShippingAddress.telephone }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Sidebar from "./Sidebar.vue";
import Order from "../../components/Orders/Order.vue";
// import Banners from "../../components/Banners/Section.vue";
// import AccountInformation from "../../components/Dashboard/AccountInformation.vue";
import { ContentLoader } from "vue-content-loader";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Order,
    ContentLoader,
    Sidebar
    // AccountInformation
  },
  data: () => ({
    reverse: "",
    defaultBillingAddress: {},
    isFetchingBanners: true,
    banners: []
  }),
  methods: {
    ...mapActions(["retrieveOrders"])
  },
  created() {
    let locale = "en";
    if (this.$i18n.locale != undefined) {
      locale = this.$i18n.locale;
    }

    let that = this;

    this.retrieveOrders().catch((error) => {
      if (error === 401) this.$router.push({ name: "Login" });
    });

    axios
      .get(
        `${process.env.VUE_APP_CMS_ENDPOINT_URL}/wp-json/vt/v1/webshop_banners/?lang=${locale}`
      )
      .then(function(response) {
        that.banners = response;
        if (response.data) {
          that.isFetchingBanners = false;
        }
      })
      .catch(error => console.log(error));
  },
  filters: {
    reverse: function(arr) {
      return arr.reverse();
    }
  },
  computed: {
    ...mapGetters(["isLoggedIn", "authUser", "orders"]),
    userBillingAddress() {
      if (this.authUser.addresses.length > 0) {
        let defaultBillingAddress = this.authUser.addresses.find(function(
          address
        ) {
          return address.default_billing;
        });
        return defaultBillingAddress;
      }
      return null;
    },

    userBillingName() {
      return "";
      // var nameElements = [
      //   this.defaultBillingAddress.prefix,
      //   this.defaultBillingAddress.firstname,
      //   this.defaultBillingAddress.middlename,
      //   this.defaultBillingAddress.lastname,
      //   this.defaultBillingAddress.suffix
      // ];
      // nameElements = nameElements.filter(function(el) {
      //   return el != null;
      // });
      // return nameElements.join(" ");
    },

    userShippingAddress() {
      if (this.authUser.addresses.length > 0) {
        let defaultShippingAddress = this.authUser.addresses.find(function(
          address
        ) {
          return address.default_shipping;
        });
        return defaultShippingAddress;
      }
      return null;
    },

    userShippingName() {
      var nameElements = [
        this.authUser.prefix,
        this.authUser.firstname,
        this.authUser.middlename,
        this.authUser.lastname,
        this.authUser.suffix
      ];
      nameElements = nameElements.filter(function(el) {
        return el != null;
      });
      return nameElements.join(" ");
    }

    // userShippingAddress() {
    //   let defaultShippigAddress = this.getUser.addresses.find(function(
    //     address
    //   ) {
    //     return address.default_shipping;
    //   });

    //   return defaultShippigAddress;
    // }

    // isActive() {
    // return this.$route.name === 'Account';
    // }
  }
};
</script>

<style lang="css" scoped></style>
