<template>
  <section class="section dealer-locator">
    <div class="section__body dealer-locator__body">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              class="dealer-locator__tool bg-light d-flex flex-column flex-lg-row"
            >
              <div class="dealer-locator__sidebar pt-4">
                <div
                  class="dealer-locator__header text-center text-lg-left px-4"
                >
                  <h3 v-t="'store-locator.title'">
                    More than 1600 stores
                  </h3>
                </div>
                <div class="dealer-locator__content px-4">
                  <p v-t="'store-locator.intro'">
                    Ut sed lectus in dui feugiat congue in nec nisi. In euismod
                    mi venenatis leo vehicula viverra.
                  </p>

                  <form
                    class="dealer-locator__form"
                    action=""
                    method=""
                    v-on:submit.prevent
                  >
                    <div class="form-group">
                      <label
                        for="input-location"
                        v-t="'store-locator.search-location'"
                        >Search for location</label
                      >
                      <div class="location-input">
                        <input
                          type="text"
                          class="form-control
                        location-input__field location-input__field--right"
                          id="input-location"
                          aria-describedby="location"
                          :placeholder="
                            $t('store-locator.postal-code-city-country')
                          "
                          @keydown.enter.prevent=""
                        />

                        <button
                          class="btn btn-sm btn-secondary btn-icon-only location-input__button location-input__button--left"
                          id="input-location-btn"
                          @click="currentLocation"
                        >
                          <i class="icon icon-gps_fixed"></i>
                          <i class="icon icon-spinning"></i>
                        </button>
                      </div>
                    </div>

                    <div class="form-group mb-0">
                      <label
                        for="input-dealer-name"
                        v-t="'store-locator.search-name'"
                        >Search for dealer name</label
                      >
                      <div class="dealer-name-input">
                        <input
                          type="text"
                          class="form-control dealer-name-input__field dealer-name-input__field--right  mb-2"
                          id="input-dealer-name"
                          aria-describedby="location"
                          :placeholder="$t('store-locator.enter-dealer-name')"
                          v-model="dealerName"
                          @input="dealerChanged"
                          @keyup.enter="dealerSearch"
                          @keydown.enter.prevent=""
                        />

                        <button
                          class="btn btn-sm btn-icon-only dealer-name-input__button d-none"
                          id="input-dealer-name-empty"
                          @click="clearDealerSearch"
                        >
                          <i class="icon icon-close"></i>
                        </button>

                        <button
                          class="btn btn-sm btn-secondary btn-icon-only location-input__button location-input__button--left"
                          id="dealer-locator__form-btn"
                          @click="dealerSearch"
                        >
                          <i class="icon icon-search"></i>
                        </button>
                      </div>
                    </div>

                    <div class="form-group text-right mb-0">
                      <button
                        class="btn btn-sm btn-empty btn-empty-primary"
                        @click="resetFilters()"
                      >
                        <span v-t="'store-locator.reset-filters'">
                          Reset filters
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  class="dealer-locator__results"
                  id="dealer-accordion"
                ></div>
                <div
                  class="dealer-locator__no-results d-none"
                  id="dealer-no-results"
                >
                  <div
                    class="dealer__button-content"
                    v-t="'store-locator.no-results'"
                  >
                    No results
                  </div>
                </div>
              </div>
              <!-- <div class="dealer-locator__map" id="map"></div> -->
              <Map
                :disableUI="false"
                :zoom="4"
                mapType="roadmap"
                :center="center"
                :dealers="filteredDealers"
                :initialDealerCount="this.vetusdealers.length"
                :mapDidLoad="handleMapDidLoad"
                :currentDealer="this.quoteDealer"
                v-if="vetusdealers.length > 0 && renderComponent"
              ></Map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Map from "./Map.vue";
import { ref } from "vue"; //

export default {
  components: {
    Map
  },
  props: {
    vetusdealers: Object
  },
  data() {
    return {
      currentDealer: 0,
      renderComponent: true
    };
  },
  setup(props) {
    // const store = useStore();
    const dealerName = ref("");
    const dealerInputName = document.getElementById("input-dealer-name");
    // const initialDealerCount = props.vetusdealers.length;

    // Will be added to GMaps component
    let filteredDealers = ref([]);

    // New array after user searches
    let newDealers = ref([]);

    // Initial center map
    let center = ref({ lat: 51.916962, lng: 4.398819 });

    // Initial dealers array
    let dealers = {};

    // Get current location
    const currentLocation = () => {
      // Add loading icon
      document.getElementById("input-location-btn").classList.add("is-loading");

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
      } else {
        alert("Geolocation is not supported by your browser");
      }
    };

    // Show position when current location found
    const showPosition = position => {
      if (position && position.coords) {
        center.value = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        document
          .getElementById("input-location-btn")
          .classList.remove("is-loading");
      }
    };

    // Error handling for current location
    const showError = error => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Please allow request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }

      // Remove loading icon
      document
        .getElementById("input-location-btn")
        .classList.remove("is-loading");
    };

    // On dealer name search
    const dealerSearch = () => {
      if (dealerName.value.length > 2) {
        // Run function to filter array by name
        filterDealers(dealerName.value);
      }
    };

    // Filter dealers array by name
    const filterDealers = (name) => {
      let dealerResults = document.getElementById("dealer-accordion");
      let dealerNoResults = document.getElementById("dealer-no-results");
      newDealers = props.vetusdealers.filter(function (dealer) {
        if (dealer.name == null) return false;
        return dealer.name.toLowerCase().includes(name.toLowerCase());
      });

      filteredDealers.value = newDealers;

      if (filteredDealers.value.length > 0) {
        dealerResults.classList.remove("d-none");
        dealerNoResults.classList.add("d-none");
      } else {
        dealerResults.innerHTML = "";
        dealerResults.classList.add("d-none");
        dealerNoResults.classList.remove("d-none");
      }
    };

    // Clear dealer name search
    const clearDealerSearch = () => {
      dealerName.value = "";

      filterDealers("");
    };

    // Handle Maps load
    const handleMapDidLoad = () => {
      filterDealers("");
      showPosition();
    };

    return {
      dealers,
      handleMapDidLoad,
      currentLocation,
      dealerSearch,
      filteredDealers,
      dealerName,
      dealerInputName,
      center,
      showPosition,
      clearDealerSearch,
      vetudealers: props.vetusdealers //computed(() => store.getters.vetudealers)
    };
  },
  methods: {
    resetFilters() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
        this.clearDealerSearch();
        document.getElementById("input-location").value = "";
      });
    }
  },
  computed: {
    ...mapGetters(["quoteDealer"])
  }
};
</script>

<style></style>
