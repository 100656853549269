<template>
  <DealerLocator
    v-if="Object.keys(vetusdealers).length !== 0"
    :vetusdealers="vetusdealers"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex"; //
import DealerLocator from "./DealerLocator.vue";

export default {
  components: {
    DealerLocator
  },
  created() {
    this.fetchDealers();
  },
  methods: { ...mapActions(["fetchDealers"]) },
  computed: {
    ...mapGetters(["vetusdealers"])
  }
};
</script>

<style></style>
