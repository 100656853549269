<template>
  <div class="dealer-locator__map map" id="map" ref="mapDivRef"></div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { mapActions, useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  components: {},
  props: {
    center: { lat: Number, lng: Number },
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
    dealers: Array,
    mapDidLoad: Function,
    currentDealer: String,
    initialDealerCount: Number,
    dealertext: String
  },
  data() {
    return {
      marker: 0,
      dealerInfo: {},
      selectedDealer: "",
      dealerText: ""
    };
  },
  setup(props, { root }) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const quoteDealer = computed(() => store.getters.quoteDealer);
    // Google map object
    const map = ref(null);

    // Map element in the template
    const mapDivRef = ref(null);
    let markers = [];
    let currentMarkers = [];

    // load in the google script
    onMounted(() => {
      // const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
      // create the script element to load
      const googleMapScript = document.createElement("SCRIPT");
      googleMapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpKWO9k1La6QxZfYZg7fIAcbDlhTfwdt4&callback=initMap`
      );
      googleMapScript.setAttribute("defer", "");
      googleMapScript.setAttribute("async", "");
      document.head.appendChild(googleMapScript);
    });

    // Clear markers
    const clearMarkers = () => {
      markers.forEach(m => {
        m.map = null;
        m.setMap(null);
      });
      markers = [];
    };

    // Load map markers
    const loadMapMarkers = () => {
      if (props.dealers.length > 0) {
        let bounds = new window.google.maps.LatLngBounds();
        let infoWindow = new window.google.maps.InfoWindow();

        // Always clear markers
        clearMarkers();

        props.dealers.forEach((dealerInfo) => {
          const dealerMarker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(
              dealerInfo.position.lat,
              dealerInfo.position.lng
            ),
            map: map.value,
            title: dealerInfo.name,
            dealer: dealerInfo,
          });

          if (props.currentDealer == dealerInfo.id) {
            dealerMarker.setIcon(
              "http://maps.google.com/mapfiles/ms/micons/green.png"
            );
          }

          let choose_dealer_name_text = "Choose dealer for quote";
          let dealer_services_text = "Available services";

          let dealerServices = `${
            dealerInfo.vetus_center && dealerInfo.vetus_center == true
              ? `<span class="dealer__badge btn btn-sm btn-rounded is-active mb-2">VETUS Service center</span>`
              : ""
          }`;
          if (dealerInfo.tags.length > 0) {
            dealerInfo.tags.forEach((service) => {
              dealerServices =
                dealerServices +
                `${`<span class="dealer__badge btn btn-sm btn-rounded is-active mb-2 mr-2">${service}</span>`}`;
            });
          }
          let infoWindowContent =
            `
            <h6 class="dealer__title w-100 d-block">
                      ${dealerInfo.name}
                  </h6>

                  ${(() => {
                    if (
                      dealerInfo.address &&
                      dealerInfo.address.street[0].length > 0
                    ) {
                      return `<address class="text-sm">
                                  ${
                                    dealerInfo.address.street[0]
                                      ? `${dealerInfo.address.street.join(
                                          ", "
                                        )}<br>`
                                      : ""
                                  }
                                  ${
                                    dealerInfo.address.postal_code
                                      ? `${dealerInfo.address.postal_code}<br>`
                                      : ""
                                  }
                                  ${
                                    dealerInfo.address.city
                                      ? `${dealerInfo.address.city},`
                                      : ""
                                  }
                                  ${
                                    dealerInfo.address.country_code
                                      ? `${dealerInfo.address.country_code}`
                                      : ""
                                  }
                                      </address>`;
                    } else {
                      return ``;
                    }
                  })()}

                  ${(() => {
                    if (dealerInfo.email && dealerInfo.email.length > 0) {
                      return `<a href="mailto:${dealerInfo.email}" class=" d-inline-flex align-items-center " target="_blank">
                                                          <i class="icon icon-mail_outline text-md mr-2"></i>
                                                          <span>
                                                              ${dealerInfo.email}
                                                          </span>
                                                      </a>`;
                    } else {
                      return ``;
                    }
                  })()}

                  ${(() => {
                    if (
                      dealerInfo.address.telephone &&
                      dealerInfo.address.telephone.length > 0
                    ) {
                      return `<br><span class=" d-inline-flex align-items-center">
                                                          <i class="icon icon-local_phone text-md mr-2"></i>
                                                          <span>
                                                              ${dealerInfo.address.telephone}
                                                          </span>
                                                      </span>`;
                    } else {
                      return ``;
                    }
                  })()}

                  ${(() => {
                    if (dealerServices.length) {
                      return `<h6 class="mt-4 mb-2">${dealer_services_text}</h6>
                      ${dealerServices}`;
                    } else {
                      return ``;
                    }
                  })()}

                  <div class="mt-4">
                    <button class="btn btn-secondary btn-sm" onclick="globalSetQuoteDealer('${
                      dealerInfo.id
                    }')">
                    <span>` +
            choose_dealer_name_text +
            `</span><i class="icon icon-keyboard_arrow_right"></i></button>
                  </div>
                  `;

          dealerMarker.addListener("click", () => {
            if (dealerMarker.title !== null) {
              infoWindow.setContent(infoWindowContent);
              infoWindow.open(map.value, dealerMarker);
              map.value.panTo(dealerMarker.getPosition());
            }
          });

          bounds.extend(dealerMarker.getPosition());
          markers.push(dealerMarker);
        });

        if (props.initialDealerCount != markers.length) {
          // Solution for zoomlevel VETUS-450
        //  map.value.fitBounds(bounds);
        }
      }

      if (props.dealers.length == 1) {
        map.value.setZoom(9);
        let currentDealerId = props.dealers[0].id;
        let currentMarkers = markers.filter(function(marker) {
          return marker.dealer.id == currentDealerId;
        });

        loadMapMarker(currentMarkers[0]);
      }

      if (props.currentDealer) {
        let currentDealerId = props.currentDealer;
        let currentMarkers = markers.filter(function(marker) {
          return marker.dealer.id == currentDealerId;
        });

        // loadMapMarker(currentMarkers[0]);
      }
    };

    const centerCurrentLocation = position => {
      map.value.setCenter(position);
    };

    const dealerResultHTML = dealerInfo => {
      return `
      <button class="btn btn-textual w-100 text-left collapsed align-items-start
justify-content-start dealer__button p-0
" data-lat="${dealerInfo.position.lat}" data-lng="${
        dealerInfo.position.lng
      }" data-id="${dealerInfo.id}">
<div class="dealer__button-content w-100
">
      <h6 class="dealer__title w-100 d-block">
                    ${dealerInfo.name}
                </h6>

                ${
                  dealerInfo.vetus_center && dealerInfo.vetus_center == true
                    ? `<span class="dealer__badge btn btn-sm btn-rounded is-active mb-2">VETUS Service center</span>`
                    : ""
                }

                ${(() => {
                  if (
                    dealerInfo.address &&
                    dealerInfo.address.street[0].length > 0
                  ) {
                    return `<address class="text-sm">
                                ${
                                  dealerInfo.address.street[0]
                                    ? `${dealerInfo.address.street.join(
                                        ", "
                                      )}<br>`
                                    : ""
                                }
                                ${
                                  dealerInfo.address.postal_code
                                    ? `${dealerInfo.address.postal_code}<br>`
                                    : ""
                                }
                                ${
                                  dealerInfo.address.city
                                    ? `${dealerInfo.address.city},`
                                    : ""
                                }
                                ${
                                  dealerInfo.address.country_code
                                    ? `${dealerInfo.address.country_code}`
                                    : ""
                                }
                                    </address>`;
                  } else {
                    return ``;
                  }
                })()}

                ${(() => {
                  if (dealerInfo.email && dealerInfo.email.length > 0) {
                    return `<span class=" d-flex align-items-center">
                                                        <i class="icon icon-mail_outline text-md mr-2"></i>
                                                        <span>
                                                            ${dealerInfo.email}
                                                        </span>
                                                    </span>`;
                  } else {
                    return ``;
                  }
                })()}

                ${(() => {
                  if (
                    dealerInfo.address.telephone &&
                    dealerInfo.address.telephone.length > 0
                  ) {
                    return `<span class=" d-flex align-items-center">
                                                        <i class="icon icon-local_phone text-md mr-2"></i>
                                                        <span>
                                                            ${dealerInfo.address.telephone}
                                                        </span>
                                                    </span>`;
                  } else {
                    return ``;
                  }
                })()}

                </div></button>
                `;
    };

    const setDealerResults = dealers => {
      const dealerResults = document.getElementById("dealer-accordion");
      dealerResults.innerHTML = "";

      for (let [i, dealer] of Object.entries(dealers)) {
        let dealerResult = document.createElement("div");
        dealerResult.classList.add("dealer");
        dealerResult.innerHTML = dealerResultHTML(dealer, i);
        dealerResults.append(dealerResult);

        if (i == 19) {
          break;
        }
      }

      let dealerBtns = document.querySelectorAll(".dealer__button");
      dealerBtns.forEach(function(btn) {
        btn.addEventListener("click", function() {
          let id = Number(btn.getAttribute("data-id"));
          let currentMarkers = markers.filter(function(marker) {
            return marker.dealer.id == id;
          });

          if (currentMarkers.length == 0) {
            return;
          }

          let lat = btn.getAttribute("data-lat");
          let lng = btn.getAttribute("data-lng");
          let latlng = new window.google.maps.LatLng(lat, lng);
          map.value.panTo(latlng);

          if (id === currentMarkers[0].dealer.id) {
            btn.classList.add("is-active");
          }

          // With zoom, infowindow doesn't alway open (more often than it does)
          // map.setZoom(10);
          // console.log("Current marker 1", currentMarkers[0]);
          window.google.maps.event.trigger(currentMarkers[0], "click");
        });
      });

      // console.log(currentMarkers);
    };

    const loadMapMarker = marker => {
      window.google.maps.event.trigger(marker, "click");
    };

    const showVisibleMarkers = (dealers, markers) => {
      let bounds = map.value.getBounds();

      let filteredDealers = markers.filter(function(marker) {
        return bounds.contains(marker.getPosition()) === true;
      });
      let filteredDealersJSON = filteredDealers.map(function(marker) {
        return marker.dealer;
      });
      setDealerResults(filteredDealersJSON);
      //
    };

    const enableEnterKey = dealerInputLocation => {
      /* Store original event listener */
      const _addEventListener = dealerInputLocation.addEventListener;

      const addEventListenerWrapper = (type, listener) => {
        if (type === "keydown") {
          /* Store existing listener function */
          const _listener = listener;
          listener = event => {
            /* Simulate a 'down arrow' keypress if no address has been selected */
            const suggestionSelected = document.getElementsByClassName(
              "pac-item-selected"
            ).length;
            if (event.key === "Enter" && !suggestionSelected) {
              const e = new KeyboardEvent("keydown", {
                key: "ArrowDown",
                code: "ArrowDown",
                keyCode: 40
              });
              _listener.apply(dealerInputLocation, [e]);
            }
            _listener.apply(dealerInputLocation, [event]);
          };
        }
        _addEventListener.apply(dealerInputLocation, [type, listener]);
      };

      dealerInputLocation.addEventListener = addEventListenerWrapper;
    };

    watch(
      () => props.dealers,
      () => {
        loadMapMarkers();
      }
    );

    watch(
      () => props.currentDealer,
      () => {
        loadMapMarker();
      }
    );

    watch(
      () => props.center,
      () => {
        centerCurrentLocation(props.center);
      }
    );

    /**
     * this function is called as soon as the map is initialized
     */
    window.initMap = () => {
      map.value = new window.google.maps.Map(mapDivRef.value, {
        mapTypeId: props.mapType || "hybrid",
        zoom: props.zoom || 15,
        disableDefaultUI: props.disableUI || false,
        center: props.center
        // center,
      });

      // map.value.setOptions({ minZoom: 5, maxZoom: 15 });

      loadMapMarkers();

      props.mapDidLoad && props.mapDidLoad(map, window.google.maps);

      map.value.addListener("idle", function() {
        showVisibleMarkers(props.dealers, markers);
      });
      loadMapMarker();

      map.value.addListener("dragend", function() {
        dealerInputLocation.value = "";
      });

      let dealerInputLocation = document.getElementById("input-location");
      const options = {
        // componentRestrictions: { country: ["nl", "be", "uk", "fr"] },
        fields: ["formatted_address", "geometry", "name"],
        origin: map.value.getCenter(),
        strictBounds: false
      };

      const autocomplete = new window.google.maps.places.Autocomplete(
        dealerInputLocation,
        options
      );
      enableEnterKey(dealerInputLocation);

      // Place change lsitener
      autocomplete.addListener("place_changed", function() {
        // Get place
        var place = autocomplete.getPlace();

        // No results, stop
        if (place.length == 0) {
          return;
        }

        if (typeof place.geometry == "undefined") {
          return;
        }

        var laLatLng = new window.google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        map.value.panTo(laLatLng);
        map.value.setZoom(12);
        map.value.panBy(-150, 0);
      });

      window.google.maps.event.addListener(map, "bounds_changed", function() {
        autocomplete.bindTo("bounds", map);
      });
    };

    const setQuoteDealer = quoteDealer =>
      store
        .dispatch("setQuoteDealer", { quoteDealer: quoteDealer })
        .then(response => {
          router.replace({ name: "Contact info" });
        })
        .catch(error => {
          console.error("Something went wrong setting dealer.");
        });

    return {
      mapDivRef,
      setQuoteDealer,
      quoteDealer
    };
  },

  methods: {},
  computed: {},
  updated() {},
  created() {},
  mounted() {
    window.globalSetQuoteDealer = this.setQuoteDealer;
  }
};
</script>

<style lang="css" scoped></style>
