<template>
  <section
    class="section"
    v-if="quoteProducts && quoteProducts.total_count > 0 && vetusdealers.length"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-2 order-lg-1">
          <div class="pt-6">
            <form v-on:submit.prevent @submit="submitQuoteRequest($event)">
              <div class="form-fields">
                <div class="form-section">
                  <div class="form-header">
                    <h3 v-t="'miscellaneous.labels.contact-info'">
                      Contact info
                    </h3>
                  </div>

                  <div class="form-row" v-if="!isLoggedIn">
                    <div class="form-group col-6">
                      <label for="input-firstname" v-t="'order.first-name-mand'"
                        >First Name *</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': v$.form.address.firstname.$error
                        }"
                        id="input-firstname"
                        aria-describedby="input-firstname"
                        placeholder="Your first name"
                        v-model="form.address.firstname"
                      />
                    </div>
                    <div class="form-group col-6">
                      <label for="input-lastname" v-t="'order.last-name-mand'"
                        >Last Name *</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': v$.form.address.lastname.$error
                        }"
                        id="input-lastname"
                        aria-describedby="input-lastname"
                        placeholder="Your last name"
                        v-model="form.address.lastname"
                      />
                    </div>
                  </div>

                  <div class="form-group" v-if="!isLoggedIn">
                    <label for="input-email" v-t="'order.email-mand'"
                      >Email *</label
                    >
                    <input
                      type="email"
                      class="form-control"
                      :class="{ 'is-invalid': v$.form.email.$error }"
                      id="input-email"
                      aria-describedby="input-email"
                      placeholder="Enter email"
                      v-model="form.email"
                    />
                    <small
                      class="form-text text-muted"
                      v-t="'order.share-email'"
                      >We'll never share your email with anyone else.</small
                    >
                  </div>

                  <div class="form-group">
                    <label for="input-phone-number" v-t="'order.phone'"
                      >Phone number
                      <span class="text-muted" v-t="'order.optional'"
                        >Optional</span
                      >
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="input-phone-number"
                      aria-describedby="input-phone-number"
                      placeholder="Phone number"
                      v-model="form.address.telephone"
                    />
                  </div>

                  <div class="form-group">
                    <label for="input-address-1" v-t="'order.address-mand'"
                      >Address *</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': v$.form.address.street.$error
                      }"
                      id="input-address-1"
                      aria-describedby="input-address-1"
                      placeholder="Address"
                      v-model="form.address.street[0]"
                    />
                    <input
                      type="text"
                      class="form-control"
                      id="input-address-2"
                      aria-describedby="input-address-2"
                      v-model="form.address.street[1]"
                    />
                    <input
                      type="text"
                      class="form-control"
                      id="input-address-3"
                      aria-describedby="input-address-3"
                      v-model="form.address.street[2]"
                    />
                  </div>

                  <div class="form-row">
                    <div class="form-group col-6">
                      <label for="input-postal-code">Postal code *</label>
                      <input
                        type="text"
                        class="form-control"
                        id="input-postal-code"
                        :class="{
                          'is-invalid': v$.form.address.postcode.$error
                        }"
                        aria-describedby="input-postal-code"
                        placeholder="Postal code"
                        v-model="form.address.postcode"
                      />
                    </div>
                    <div class="form-group col-6">
                      <label for="input-city" v-t="'order.city-town-mand'"
                        >City / town *</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="input-city"
                        :class="{ 'is-invalid': v$.form.address.city.$error }"
                        aria-describedby="input-city"
                        placeholder="City"
                        v-model="form.address.city"
                      />
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-6" v-if="countries">
                      <label for="input-country" v-t="'order.country-mand'"
                        >Country *</label
                      >
                      <div class="custom-control custom-selectbox">
                        <select
                          class="form-control"
                          id="input-country"
                          :class="{
                            'is-invalid': v$.form.address.country_code.$error
                          }"
                          v-model="form.address.country_code"
                          @change="countrySelected($event)"
                        >
                          <!-- <option selected="">Select a country </option> -->
                          <template v-for="(country, i) in countries" :key="i">
                            <option :value="country.two_letter_abbreviation">
                              {{ country.full_name_locale }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>

                    <div
                      class="form-group col-6"
                      v-if="regions && regions.length > 0"
                    >
                      <label
                        for="input-region"
                        v-t="'order.state-province-mand'"
                        >State / province *</label
                      >
                      <div class="custom-control custom-selectbox">
                        <select
                          class="form-control"
                          id="input-region"
                          v-model="form.address.region_id"
                        >
                          <!-- <option selected="">Select a region </option> -->
                          <template v-for="(region, i) in regions" :key="i">
                            <option :value="region.id">
                              {{ region.name }}
                            </option>
                          </template>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="input-company" v-t="'order.company'"
                      >Company
                      <span class="text-muted" v-t="'order.optional'"
                        >Optional</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="input-company"
                      aria-describedby="input-company"
                      placeholder="Company"
                      v-model="form.address.company"
                    />
                  </div>

                  <div class="form-group mb-0">
                    <label for="input-remarks" v-t="'contact-info.comment'"
                      >Comment
                      <span class="text-muted" v-t="'order.optional'"
                        >Optional</span
                      >
                    </label>
                    <textarea
                      type="text"
                      class="form-control"
                      id="input-remarks"
                      aria-describedby="input-remarks"
                      placeholder="Your comment"
                      v-model="form.remarks"
                      rows="4"
                    />
                  </div>

                  <hr />

                  <div class="form-group">
                    <div
                      class="custom-control custom-control--styled custom-check"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input custom-control-input--styled"
                        id="newsletter-signup"
                        name="newsletter-signup"
                        v-model="form.subscribe_newsletter"
                      />
                      <label
                        class="custom-control-label custom-control-label--styled"
                        for="newsletter-signup"
                        v-t="'contact-info.newsletter-signup'"
                      >
                        Yes, I would like to subscribe to your newsletter and
                        receive the latest information about offers, promotions
                        and news.
                      </label>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <div
                      class="custom-control custom-control--styled custom-check"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input custom-control-input--styled"
                        id="agree"
                        name="agree"
                        v-model=""
                      />
                      <label
                        class="custom-control-label custom-control-label--styled"
                        for="agree"
                      >
                        I agree with the VETUS Privacy Policy
                      </label>
                    </div>
                  </div> -->

                  <div class="form-section">
                    <div class="form-group text-right">
                      <button
                        type="submit"
                        class="btn btn-secondary"
                        v-t="'order.submit-request'"
                      >
                        Submit request
                      </button>
                    </div>
                    <div class="form-group" v-if="errors">
                      <div class="box box--error bg-light py-2 px-3">
                        <span class="text-xs">{{ errorMsg }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="col-lg-6 order-1 order-lg-2">
          <div class="sticky">
            <div class="box">
              <div class="box__body bg-light">
                <div class="box__content">
                  <h3 v-t="'order.order-summary'">Order summary</h3>
                  <div class="d-flex align-items-center text-sm">
                    <span class="mr-3"> {{ quoteCount }} products </span>
                    <router-link
                      :to="{ name: 'Quote' }"
                      class="btn btn-sm btn-empty"
                    >
                      <span v-t="'order.adjust'"> Adjust </span>
                    </router-link>

                    <span class="ml-auto" v-t="'miscellaneous.labels.quantity'">
                      Quantity
                    </span>
                  </div>
                  <table class="table table--products w-100">
                    <tr
                      valign="top"
                      v-for="(product, i) in quoteProducts.items"
                      :key="i"
                    >
                      <td class="pb-0">
                        <strong class="d-block ml-3"
                          ><span class="dash">-</span> {{ product.name }}
                          <span class="text-xs">({{ product.sku }})</span>
                        </strong>
                      </td>
                      <td class="pb-0 pl-2">
                        <strong>{{ quote[product.sku] }}</strong>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="box" v-if="!isLoggedIn && getDealerInfo[0]">
              <div class="box__body">
                <div class="box__content">
                  <h3 v-t="'contact-info.request-send'">
                    Request will be sent to
                  </h3>
                  <div class="d-flex align-items-center">
                    <span class="mr-3">
                      <h5>
                        {{ getDealerInfo[0].name }}
                      </h5>
                    </span>
                    <router-link
                      :to="{ name: 'Choose a dealer' }"
                      class="btn btn-sm btn-empty btn-empty-primary"
                      title=""
                    >
                      <span v-t="'order.adjust'"> Adjust </span>
                    </router-link>
                  </div>
                  <div class="box__actions actions">
                    <div
                      class="btn-group d-flex flex-wrap flex-grow-1 flex-column align-items-start"
                    >
                      <a
                        :href="'mailto:' + getDealerInfo[0].email"
                        class="btn btn-sm btn-empty btn-empty-reverse"
                        target="_blank"
                        v-if="getDealerInfo[0].email"
                      >
                        <i class="icon icon-mail_outline"></i>
                        <span>
                          {{ getDealerInfo[0].email }}
                        </span>
                      </a>
                    </div>
                  </div>
                  <div class="box__address">
                    <div class="mb-2">
                      <strong v-t="'order.address'"> Address </strong>
                    </div>
                    <address class="text-sm">
                      <span
                        v-for="(street, i) in getDealerInfo[0].address.street"
                        :key="i"
                      >
                        {{ street }} </span
                      ><br />
                      {{ getDealerInfo[0].address.postcode }},
                      {{ getDealerInfo[0].address.city }}<br />
                      {{ getDealerInfo[0].address.country_code }}<br />
                      <span v-if="this.getDealerInfo[0].address.telephone">
                        t: {{ getDealerInfo[0].address.telephone }}
                      </span>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section products products--empty" v-else>
    <div class="container text-center">
      <p v-t="'contact-info.quote'">
        Your quote is empty. Visit the webshop to add products to your quote.
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  email,
  minValue,
  requiredIf,
  minLength
} from "@vuelidate/validators";
// import ProductPrice from "../../components/Products/ProductPrice.vue";
// import PlaceOrder from "../../components/Forms/PlaceOrder.vue";

const customRequired = value => {
  if (!Array.isArray(value)) {
    return false;
  }
  if (value.length == 0) {
    return false;
  }

  return value[0].length > 0;
};

export default {
  components: {
    // ProductPrice
    // PlaceOrder
  },
  props: {},
  data() {
    return {
      regions: null,
      errors: false,
      errorMsg: "",
      form: {
        dealer_id: 0,
        items: [],
        subtotal: 0,
        address: {
          firstname: "",
          lastname: "",
          company: "",
          street: [],
          city: "",
          region_id: "",
          postcode: "",
          country_code: "",
          telephone: ""
        },
        email: "",
        remarks: "",
        subscribe_newsletter: false
      }
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    if (!this.isLoggedIn) {
      return {
        form: {
          address: {
            firstname: { required },
            lastname: { required },
            street: { customRequired },
            city: { required },
            region_id: {},
            postcode: { required },
            country_code: { required }
          },
          email: {
            required,
            email
          }
        }
      };
    } else {
      return {
        form: {
          address: {
            street: { customRequired },
            city: { required },
            region_id: {},
            postcode: { required },
            country_code: { required }
          },
          email: {
            required,
            email
          }
        }
      };
    }
  },
  computed: {
    ...mapGetters([
      "quote",
      "isLoggedIn",
      "quoteCount",
      "quoteProducts",
      "quoteDealer",
      "vetusdealers",
      "countries",
      "authUser"
    ]),
    getDealerInfo: function() {
      // return this.vetusdeal  ers;
      // return this.quoteDealer;
      let quoteDealer = this.quoteDealer;
      return this.vetusdealers.filter(function(obj) {
        return obj.id == quoteDealer;
      });
    }
  },
  created() {
    this.fetchDealers();
    this.fetchQuoteProducts();
    this.fetchCountries();

    if (!this.isLoggedIn && !localStorage.quoteDealer) {
      this.$router.replace({ name: "Quote" });
      return;
    }
    if (this.quoteDealer) {
      this.form.dealer_id = this.quoteDealer;
    }

    if (this.quoteProducts.items) {
      this.form.items = this.quoteProducts.items;
    }
  },
  watch() {},
  methods: {
    ...mapActions([
      "fetchDealers",
      "fetchQuoteProducts",
      "submitQuote",
      "fetchCountries"
    ]),
    focusFirstStatus() {
      let invalids = document.querySelector(".is-invalid");
      if (invalids) {
        let domRect = document
          .querySelector(".is-invalid")
          .getBoundingClientRect();
        if (domRect) {
          window.scrollTo(
            domRect.left + document.documentElement.scrollLeft,
            domRect.top + document.documentElement.scrollTop
          );
        }
      }
    },
    submitQuoteRequest() {
      this.form.errors = [];
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        if (this.quoteProducts.items.length) {
          this.submitQuote(this.form)
            .then(response => {
              this.$router.replace({
                name: "Confirmation Quote",
                params: { quoteNumber: response.number }
              });
            })
            .catch(() => {
              this.errors = true;
              this.errorMsg = this.$t("contact-info.errors.something-wrong");
            });
        }
      } else {
        this.$nextTick(() => this.focusFirstStatus());
      }
    },
    countrySelected(event) {
      // this.form.address.region = "";
      this.form.address.region_id = 0;
      let selectedCountry = this.countries.filter(function(country) {
        return country.two_letter_abbreviation == event.target.value;
      });
      if (selectedCountry.length) {
        this.regions = selectedCountry[0].available_regions;
        if (this.regions) {
          this.form.address.region_id =
            selectedCountry[0].available_regions[0].id;
        }
      } else {
        this.regions = {};
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
