<template lang="html">
  <router-view />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  created() {},
  computed: {
    ...mapGetters(["isLoggedIn", "authStatus"])
  }
};
</script>

<style lang="css" scoped></style>
